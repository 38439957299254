import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EnryptionService {
  private secretKey: string = 'RDp1HfVKenr/FdQ1rQkxZmcR93879wr07wWRFFHsDn8=';
 
  constructor() { }
  
 
    public encrypt(txt: string): string {
      return CryptoJS.AES.encrypt(txt, this.secretKey).toString();
    }
    
    public decrypt(txtToDecrypt: string) {
      return CryptoJS.AES.decrypt(txtToDecrypt, this.secretKey).toString(CryptoJS.enc.Utf8);
    }
}
