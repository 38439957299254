import { Component } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
 

@Component({
  selector: 'ngx-communication-logs-list',
  templateUrl: './communication-logs-list.component.html',
  styleUrls: ['./communication-logs-list.component.scss']
})
export class CommunicationLogsListComponent {
  communicationlist: any []= [];
  CommunicationSettings: Ng2SmartTableModule = {
    mode: 'external',
    actions: { add: false, edit : false, delete : false }, // Désactiver le bouton "Add New"
   
    columns: {
      userName: { title: 'User Name' },
      role: { title: 'Role' },
      date: { title: 'Date' },
      description: { title: 'Description' },
      problem: { title: 'Problem' },
      severity: { title: 'Severity' },
      actions: { 
        title : "Actions",
        custom : [
       
         { name : "delete",
         title: '<span class="material-icons">delete</span>'}
           
        
           
      ]
        
      }
    },
  };

  // Vous pouvez supprimer la fonction getActionIcons() car elle n'est plus nécessaire
}
