import { Injectable } from '@angular/core';
import { audit } from '../models/audit';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  // Update the base URL to use http
  private baseUrl = 'https://gateway.manajero.com/monitoring-service';

  constructor(private http: HttpClient) { }

  getProjectLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`https://gateway.manajero.com/monitoring-service/allProjects`);
  }

  getRecoveryLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/getAllRecovery`);
  }

  getArchivedLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/sse-audit-logs-HCM`);
  }

  getAuditLogsStock(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/getAll`);
  }

  getAuditLogsMarketing(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/getAllMarketing`);
  }

  getAuditFinanceLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/getAllFinance`);
  }

  getAuditAccLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/sse-getAllAccountig`);
  }

  getAuditCrmLogs(): Observable<audit[]> {
    return this.http.get<audit[]>(`${this.baseUrl}/sse-getAllCrm`);
  }

}