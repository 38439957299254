import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SecurityAlert } from 'app/modules/Monitoring-management/models/SecurityAlert';
import { LogsService } from 'app/modules/Monitoring-management/services/logs.service';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'ngx-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss']
})
export class AlertsListComponent implements OnInit, OnDestroy {
  alerts: SecurityAlert[] = [];
  filteredAlerts: SecurityAlert[] = [];
  pagedAlerts: SecurityAlert[] = [];
  sortDirection: 'asc' | 'desc' = 'desc'; // Default sort direction
  currentTheme: string;
  themeSubscription: Subscription;

  // Pagination-related variables
  pageSize = 10; // Number of alerts per page
  pageIndex = 0; // Current page index

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private alertService: LogsService,
    private toastrService: NbToastrService,
    private themeService: NbThemeService
  ) {}

  ngOnInit(): void {
    this.alertService.getAlerts().subscribe((data) => {
      this.alerts = data;
      this.filteredAlerts = this.alerts;
      this.sortAlerts();
      this.updatePagedAlerts(); // Initialize pagination
    });

    // Subscribe to theme changes
    this.themeSubscription = this.themeService.onThemeChange()
      .subscribe((theme) => {
        this.currentTheme = theme.name; // Get the active theme
        this.applyThemeAdjustments();   // Apply theme-based adjustments if needed
      });
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  // Pagination event handler
  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagedAlerts();
  }

  updatePagedAlerts(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedAlerts = this.filteredAlerts.slice(startIndex, endIndex);
  }

  exportAlerts(): void {
    const csvContent = [
      ['Timestamp', 'Message', 'User', 'Level'],
      ...this.filteredAlerts.map(alert => [
        alert.timestamp,
        alert.message,
        alert.username,
        alert.level
      ])
    ].map(e => e.join(",")).join("\n");
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'security_alerts.csv');
    a.click();
  }

  sortAlerts(): void {
    this.filteredAlerts = [...this.filteredAlerts].sort((a, b) => {
      const dateA = new Date(a.timestamp).getTime();
      const dateB = new Date(b.timestamp).getTime();
      return this.sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    this.updatePagedAlerts(); // Re-paginate after sorting
  }

  toggleSortDirection(): void {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortAlerts();
  }

  showAlert(alert: SecurityAlert): void {
    this.toastrService.info(alert.message, `Alert from ${alert.username}`);
  }

  filterAlerts(query: string): void {
    this.filteredAlerts = this.alerts.filter(alert => 
      alert.message.toLowerCase().includes(query.toLowerCase()) ||
      alert.username.toLowerCase().includes(query.toLowerCase())
    );
    this.sortAlerts(); // Re-sort the filtered results
  }

  applyThemeAdjustments(): void {
    // Example: Add custom logic based on the theme
    if (this.currentTheme === 'dark') {
      console.log('Applying adjustments for dark theme');
      // Add specific adjustments if required for dark mode
    } else if (this.currentTheme === 'light') {
      console.log('Applying adjustments for light theme');
      // Add specific adjustments for light mode
    }
  }
}
