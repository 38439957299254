import { Component } from '@angular/core';
import { logs } from 'app/modules/Monitoring-management/models/logs';

@Component({
  selector: 'ngx-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent {
 

}
