import { Component} from '@angular/core';



@Component({
  selector: 'ngx-overview-logs-list',
  templateUrl: './overview-logs-list.component.html',
  styleUrls: ['./overview-logs-list.component.scss']
})
export class OverviewLogsListComponent {
  constructor() { }

 

}
