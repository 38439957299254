<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <!-- Ajoutez la balise link ici -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<body>
  <nb-card>
    <nb-card-header>
      <div class="text-center">
        <h4>Communication Logs</h4>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <!-- Barre de recherche -->
          <input nbInput nbTooltip="Tap here to search your Charter" nbTooltipStatus="primary" placeholder="Filter" type="text">
        </div>
        <div class="col-md-6 text-right">
          <!-- Bouton d'exportation -->
          <button class="btn btn-primary" style="box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); border-radius: 5px;">
            <i class="fas fa-file-export text-white"></i> Export Data
          </button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <!-- Utilisez le composant ng2-smart-table pour afficher les données -->
      <ng2-smart-table [settings]="CommunicationSettings" [source]="communicationlist" class="order-table">
        <ng-template ng2-smart-table-tbody-custom-component let-rowData="rowData" let-value="value" let-row="row">
          <custom-action-buttons [rowData]="rowData"></custom-action-buttons>
        </ng-template>
      </ng2-smart-table>
    </nb-card-body>
  </nb-card>
</body>

</html>
