import { Component } from '@angular/core';

@Component({
  selector: 'ngx-access-control-edit',
  templateUrl: './access-control-edit.component.html',
  styleUrls: ['./access-control-edit.component.scss']
})
export class AccessControlEditComponent {

}
