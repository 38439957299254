import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl='https://gateway.manajero.com/monitoring-service/api/reports'

  constructor(private http: HttpClient) { }
  

  generateReport(): Observable<string> {
    return this.http.get(`https://gateway.manajero.com/generate`, {responseType: 'text'});
  }

  downloadReport(): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/download`, { responseType: 'blob' });
  }
}