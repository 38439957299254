import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {MetricData} from '../models/MetricData';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  private currentMetricsUrl = 'https://gateway.manajero.com/monitoring-service/api/metrics/current';
  private historyMetricsUrl = 'https://gateway.manajero.com/monitoring-service/api/metrics/history';

  constructor(private http: HttpClient) {}

  getCurrentMetrics(): Observable<MetricData> {
    return this.http.get<MetricData>(this.currentMetricsUrl);
  }

  getMetricsHistory(): Observable<any[]> {
    return this.http.get<any[]>(this.historyMetricsUrl);
  }
}
