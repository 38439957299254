<nb-card>
  <nb-card-header>
    <div class="text-center">
      <h4>Access Control</h4>
    </div>
    <div class="text-right">
    </div>
  </nb-card-header>
  
  <nb-card-body>
<div class="section">
  <div class="section-header">
    <div class="section-actions">
    </div>
  </div>
  <div class="section-content">
    <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
  </div>
</div>
</nb-card-body>
</nb-card>
