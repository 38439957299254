import { Injectable } from '@angular/core';
import {EnryptionService} from '../../../Security-management/services/enryption.service'
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USERID_KEY = 'userId';
const USERROLE_KEY = 'userrole';
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private encryptionService: EnryptionService) { }
  
  signOut(): void {
    window.localStorage.clear();
  }
  public saveToken(token: string): void {

    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }
  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }
  $
  public saveUser(user: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(user));
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, encryptedUser);
  }
  public userId(userId: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(userId));
    window.localStorage.removeItem(USERID_KEY);
    window.localStorage.setItem(USERID_KEY, encryptedUser);
  } 
  public userrole(userrole: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(userrole));
    window.localStorage.removeItem(USERROLE_KEY);
    window.localStorage.setItem(USERROLE_KEY, encryptedUser);
  }

  // Get the user from local storage with decryption
  public getUser(): any {
    const encryptedUser = window.localStorage.getItem(USER_KEY);
    if (encryptedUser) {
      try {
        const decryptedUser = this.encryptionService.decrypt(encryptedUser);
        return JSON.parse(decryptedUser);
      } catch (error) {
        console.error('Error decrypting user:', error);
        return {};
      }
    }
    return {};
  }
  // Get the userrole from local storage with decryption
  public getUserRole(): any {
    const encryptedUser = window.localStorage.getItem(USERROLE_KEY);
    if (encryptedUser) {
      try {
        const decryptedUser = this.encryptionService.decrypt(encryptedUser);
        return JSON.parse(decryptedUser);
      } catch (error) {
        console.error('Error decrypting user:', error);
        return {};
      }
    }
    return {};
  }
  public clearData() {
    localStorage.clear();
  }

 
}
