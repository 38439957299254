<div class="row">
  <div class="col-md-4">
    <nb-card>
      <nb-card-header>Current CPU Usage</nb-card-header>
      <nb-card-body>{{ currentMetrics?.cpuUsage | number:'1.0-2' }}%</nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-4">
    <nb-card>
      <nb-card-header>Current Memory Usage</nb-card-header>
      <nb-card-body>{{ currentMetrics?.memoryUsage | number }} MB</nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-4">
    <nb-card>
      <nb-card-header>Total Memory Usage</nb-card-header>
      <nb-card-body>{{ currentMetrics?.totalMemory | number }} MB</nb-card-body>
    </nb-card>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header>Historical Data</nb-card-header>
      <nb-card-body>
        <ngx-charts-line-chart
          [view]="[1000, 600]"  
          [scheme]="colorScheme"
          [results]="chartData"
          [gradient]="false"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="'Date'"
          [yAxisLabel]="'Usage'"
          [autoScale]="true">
        </ngx-charts-line-chart>
      </nb-card-body>
    </nb-card>
  </div>
</div>



<nb-card>
  <nb-card-header>
    <span style="font-size: 1.2em; font-weight: bold;">Report Management</span>
    <p style="font-size: 0.9em; color: #888;">Manage and download system state reports</p>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-around">
        <button nbButton status="info" (click)="generateReport()" [disabled]="isLoading">
          <nb-icon *ngIf="!isLoading" icon="file-text-outline"></nb-icon>
          <nb-spinner *ngIf="isLoading" status="primary" size="small"></nb-spinner>
          {{ isLoading ? 'Generating...' : 'Generate Report' }}
        </button>
        
        <button nbButton status="info" (click)="exportAsXLSX()">
          <nb-icon icon="download-outline"></nb-icon>
          Download
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>

