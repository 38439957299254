import { Component, Input, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { logs } from 'app/modules/Monitoring-management/models/logs';
import { LogsService } from 'app/modules/Monitoring-management/services/logs.service';

@Component({
  selector: 'ngx-activity-logs-details',
  templateUrl: './activity-logs-details.component.html',
  styleUrls: ['./activity-logs-details.component.scss']
})
export class ActivityLogsDetailsComponent implements OnInit {
  @Input() selectedLog: logs;
  flippedState: boolean = false;
  @Input() currentLog: logs; // Added semicolon
  isDarkMode: boolean = false;

  logsModel: logs[]; // Added semicolon
  data: any; // Changed data type to any

  logProperties: { label: string, value: string }[] = []; // Declared logProperties array

  constructor(private logService: LogsService,private themeService: NbThemeService) {
    this.themeService.onThemeChange()
    .subscribe(theme => {
      this.isDarkMode = theme?.name=== 'dark';
     // console.log('Theme changed:', theme?.name, 'Is Dark Mode:', this.isDarkMode);
    });  
  } 

  ngOnInit(): void {
    this.logProperties = [
      
      { label: 'Logger', value: this.selectedLog?.logger || '-' },
      { label: 'Browser', value: this.selectedLog?.browser || '-' },
      { label: 'Username', value: this.selectedLog?.userName || '-' }, 
      { label: 'Device', value: this.selectedLog?.device || '-' },
      { label: 'Thread', value: this.selectedLog?.thread || '-' },
      { label: 'Level', value: this.selectedLog?.level || '-' },
      { label: 'Time', value: this.formatTimestamp(this.selectedLog?.timestamp) || '-' },
      { label: 'Message', value: this.selectedLog?.message || '-' },
      { label: 'IP Address', value: this.selectedLog?.ipAddress || '-' },
      { label: 'Session ID', value: this.selectedLog?.sessionId || '-' },
      { label: 'Operating System', value : this.selectedLog?.operatingSystem || '-'},
      { label: 'Role', value: this.formatRole(this.selectedLog?.role) || '-' }

    ];
  }
  formatRole(role: string): string {
    if (!role) {
      return role;
    }

    // Split the string by underscores, capitalize the first letter of each word, and join them with spaces
    return role.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  getPropertyValue(label: string): string {
    const property = this.logProperties.find(prop => prop.label === label);
    return property ? property.value : '-';
  }
  
  
   formatTimestamp(timestamp: Date): string {
      if (!timestamp) return ''; // Return empty string if timestamp is not provided
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    }
  
  Display(): void {
   // console.log(this.selectedLog, "+", this.currentLog);
  }

  flip(): void {
    this.flippedState = !this.flippedState;
  }
  getAlertColor(level: string): string {
    if (level === "WARN") {
      return "warning";
    } else if (level === "ERROR") {
      return "danger";
    } else if (level === "INFO") {
      return "success";
    } else {
      return "secondary"; // Default color if none of the conditions match
    }
  }
}
