import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';
import {logs} from '../../../models/logs';
import { Browser } from 'leaflet';
import { ExportService } from 'app/shared/exports/export.service';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {DocumentService} from 'app/modules/Monitoring-management/services/document.service';

@Component({
  selector: 'ngx-activity-logs-list',
  templateUrl: './activity-logs-list.component.html',
  styleUrls: ['./activity-logs-list.component.scss'],
})
export class ActivityLogsListComponent implements OnInit {
  source: LocalDataSource = new LocalDataSource();
  data: any[] = [];
  selectedLog = null;
  list : logs[]=[];
  isLoading = true;
  flippedState: boolean = false;
  value: String [] = [];

  constructor(private http: HttpClient, private exportService: ExportService , private documentService : DocumentService ) {}

  ngOnInit(): void {
    this.fetchLogs();
            

  }

  fetchLogs(): void {

    fetch('https://gateway.manajero.com/api/auth/logs')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json(); 
        })
        .then(data => {
            // Handle each log entry here    
            //console.log(data);
            // Update your data, list, or any other variables as needed
            this.data.push(...data);
            this.list.push(...data);
            this.source.load(this.data);
        })
        .catch(error => {
            console.error('Error fetching log data:', error);
            // Handle fetching error
        });
  }
  
  onLogRowSelect(event): void {
    this.selectedLog = event.data;
    this.flip();
   // console.log('iciiiiiEya',this.selectedLog);

  }

  flipBack() {
    this.flippedState = false;
    this.selectedLog = null;
  }
  
  flip() {
    this.flippedState = !this.flippedState;
    if (this.flippedState) {
      this.source.load(this.list); // Load data into the table
    }
  }
    
  
  settings = {
    mode: 'external',
    actions: {
      add: false, 
      edit: false, 
      delete: false,
      // custom: [
      //   { name: 'commentaire', 
        
      //    title: '<span style=" display: flex; justify-content: center; align-items: center; height: 100%; margin-top: 100%; padding-top: 100%;"><i class="fas fa-sticky-note fa-xs"></i></span>' }
      // ],
    },
    columns: {
      logger: {
        title: 'Logger',
        width: '70px',
        type: 'string',
        valuePrepareFunction:(logger)=>{
          const resSplit = logger.split('.')
          const resFinal = resSplit.pop()
        return resFinal
        }
        
      },
      userName: {
        title: 'Username',
        type: 'string',
        width: '80px',
      },
    
      timestamp: {
        title: 'Timestamp',
        width: '70px',
        type: 'string',
        sortDirection: 'desc',
        valuePrepareFunction:(timestamp)=>{
          const date = new Date(timestamp)
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        return formattedDate
        }
       
      },
      level: {
        title: 'Level',
        type: 'html',
        filter: true,
        valuePrepareFunction: (level) => {

          switch (level) {
            case 'INFO': {
              return `<h5><span class="badge badge-primary">${level}</span></h5>`;
            }
            case 'ERROR': {
              return `<h5><span class="badge badge-danger">${level}</span></h5>`;
            }
            case 'WARN': {
              return `<h5><span class="badge badge-warning">${level}</span></h5>`;
            }
            default: {
              return `<h5><span class="badge badge-basic"> </span></h5>`;
            }
          }
        }
       
      },
      // severity: {
      //   title: 'Severity',
      //   type: 'html',
      //   valuePrepareFunction: (row) => {
      //     const level = row.level;  // Access the level field from the row data
      //     switch (level) {
      //       case 'ERROR': {
      //         return `<h5><span class="badge badge-danger">HIGH</span></h5>`;
      //       }
      //       case 'WARN': {
      //         return `<h5><span class="badge badge-warning">MEDIUM</span></h5>`;
      //       }
      //       case 'INFO': {
      //         return `<h5><span class="badge badge-primary">LOW</span></h5>`;
      //       }
      //       default: {
      //         return `<h5><span class="badge badge-basic">UNKNOWN</span></h5>`;
      //       }
      //     }
      //   }
      // },
      
      thread: {
        title: 'Thread',
        type: 'string',
        width: '150px',
       
      },
      browser: {
        title: 'Browser',
        width: '100px',
        type: 'html',
        valuePrepareFunction: (browser) => {
          if (browser && typeof browser === 'string') { 
            const resSplit = browser.split(' ')[0];
           // console.log(resSplit, "iciiiiiiiiiiiiiiiiiiiii")
            if (resSplit.toLowerCase() === "chrome") {
              return `<span><i class="fab fa-chrome fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
            } else if (resSplit.toLowerCase() === "mozilla") {
              return `<span><i class="fab fa-firefox fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
            } else if(resSplit.toLowerCase() === "opera"){
              return `<span><i class="fab fa-opera fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
            }else if(resSplit.toLowerCase() === "instagram"){
              return `<span><i class="fab fa-instagram fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
            }else if(resSplit.toLowerCase() === "facebook"){
              return `<span><i class="fab fa-facebook fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
            }else {
            return `<span><i class="fab fa-edge" style="padding-right: 20%;"></i></span> `+` ${resSplit}`;
          }
        }
        }     
        
       
      },
      device: {
        title: 'Device',
        type: 'string',
        width: '180px',
      },
    
     
     
    },
  };
  
  exportAsXLSX(): void {
    // Create a new array to hold the data for export
    const dataForExport = this.list.map(logs => ({
      Logger: logs.logger,
      Thread: logs.thread,
      Device: logs.device,
      Browser: logs.browser,
      Level: logs.level,
      Time: logs.timestamp,
      Username : logs.userName,
      Message : logs.message,
      SessionID : logs.sessionId,
      IPAddrees : logs.ipAddress,
      OperatingSystem : logs.operatingSystem,
      Role: logs.role

      
    }));
    //console.log(dataForExport,"exceel")
    // Use the new array for the export
    this.exportService.exportAsExcelFile(dataForExport, 'dataUsers');
    const trace = {
      fileName: 'Activity_logs.xlsx',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      module: 'Monitoring',
      createdAt: new Date().toISOString()
    };

    this.documentService.saveTrace(trace).subscribe({
      next: () => console.log('Trace saved successfully'),
      error: (err) => console.error('Error saving trace', err)
    });

  }   
  selectedMethod() {
    console.log('Selected Values:', this.value);

    if (!Array.isArray(this.value)) {
      this.value = [this.value];
    }

    if (this.value.length > 0 && !this.value.includes('all levels')) {
      const searchString = this.value.join(',');
      console.log('Search String:', searchString);
      this.source.setFilter([
        {
          field: 'level',
          search: searchString,
        }
      ], true);
      console.log('Filtered Data:', this.source.getFilter());
    } else {
      this.source.setFilter([], true);
      console.log('All Data:', this.source.getAll());

    }
  }
  
  
  
  
  
  



  
}
