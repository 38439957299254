import { Component } from '@angular/core';
import { audit } from 'app/modules/Monitoring-management/models/audit';
import { AuditService } from 'app/modules/Monitoring-management/services/audit.service';
import { AuthService } from 'app/modules/auth/service/auth.service';
import { ExportService } from 'app/shared/exports/export.service';
import { LocalDataSource, Ng2SmartTableModule } from 'ng2-smart-table';
import {DocumentService} from 'app/modules/Monitoring-management/services/document.service';

@Component({
  selector: 'ngx-recovery-logs-list',
  templateUrl: './recovery-logs-list.component.html',
  styleUrls: ['./recovery-logs-list.component.scss']
})
export class RecoveryLogsListComponent {
  data: audit[];
  value: string = 'All Methods'; // Default value for Methods filter
  values: string = 'All Status'; // Default value for Status filter
  source: LocalDataSource = new LocalDataSource();
  constructor(private auditService: AuditService,private exportService: ExportService,private auth:AuthService,private documentService : DocumentService){}
 RecoverytSettings: Ng2SmartTableModule = {
    mode: "external",
    actions: { edit: false, delete: false, add: false },
    columns: {
      timestamp: {
        title: 'Date',
        sortDirection: 'desc',
        valuePrepareFunction: (timestamp) => {
          const date = new Date(timestamp);
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
            .getHours()
            .toString()
            .padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
          return formattedDate;
        },
        cellClass: 'date-cell',
      },
      userId: {
        title: "Author",
      },
      action: {
        title: "Method",
        type: "html",
      valuePrepareFunction: (action) => {
        switch (action) {
          case 'POST':
            return `<span class="badge badge-success" style="font-size: 120% !important;" >POST</span>`;
          case 'GET':
            return `<span class="badge badge-primary">GET</span>`;
          case 'PUT':
            return `<span class="badge badge-warning">PUT</span>`;
          case 'DELETE':
            return `<span class="badge badge-danger">DELETE</span>`;
          default:
            return `<h5><span class="badge badge-basic"> </span></h5>`;
        }
      },
    },
    
      resource: {
        title: "Name",
      },
      httpStatus: {
        title: "Status",
      },
      messageAudit: {
        title: "Activity",
      },
      sessionId: {
        title: 'Session ID'
      },
     
    },
  };
  getRecLogs() {
    this.auditService.getRecoveryLogs().subscribe((res) => {
      this.data = res;
      // console.log(this.data, "hnee");
      this.source.load(this.data);
      
    });
  }
  selectedMethod() {
    if (this.value === 'All Methods') {
      this.source.reset();
    } else {
      this.source.setFilter(
        [
          {
            field: 'action',
            search: this.value,
          },
        ],
        true
      );
    }
  }

  selectedValues() {
    if (this.values === 'All Status') {
      this.source.reset();
    } else {
      this.source.setFilter(
        [
          {
            field: 'httpStatus',
            search: this.values,
          },
        ],
        true
      );
    }
  }
ngOnInit() {
  this.getRecLogs();
}
exportAsXLSX(): void {
  // Create a new array to hold the data for export
  const dataForExport = this.data.map(logs => ({
    Author: logs.userId,
    Method: logs.action,
    Name: logs.resource,
    Time: logs.timestamp,
    Username : logs.userId,
    Message : logs.messageAudit,
    Status : logs.httpStatus,
    SessionId: logs.sessionId
    
    
  }));
  this.exportService.exportAsExcelFile(dataForExport, 'ActivityRecoveryLogs');
  const trace = {
    fileName: 'Recovery_Logs.xlsx',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    module: 'Monitoring',
    createdAt: new Date().toISOString()
  };

  this.documentService.saveTrace(trace).subscribe({
    next: () => console.log('Trace saved successfully'),
    error: (err) => console.error('Error saving trace', err)
  });
}

}
