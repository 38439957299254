import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HumainCapitalManagementRoutingModule } from './humain-capital-management-routing.module';
import {NgxOrgChartModule} from 'ngx-org-chart';
import { CKEditorModule } from 'ng2-ckeditor';
import {
  NbAccordionModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbListModule,
  NbProgressBarModule,
  NbStepperModule,
  NbTabsetModule,
  NbToggleModule,
  NbTooltipModule,
  NbTableModule,
  NbChatModule,
  NbIconModule,
  NbMenuModule,
  NbSelectModule,
  NbSidebarModule,
  NbToastrModule,
  NbLayoutModule,
  NbInputModule,
  NbSpinnerModule,
  NbActionsModule,
  NbTagModule,
  NbTreeGridModule, NbThemeModule, COSMIC_THEME, CORPORATE_THEME, DARK_THEME,
  NbOptionModule,
  NbUserModule, NbWindowModule,
} from '@nebular/theme';
import {NgxCopyPasteModule} from 'ngx-copypaste';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {NgxPaginationModule} from 'ngx-pagination';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {NgxPrintModule} from 'ngx-print';
import { ReadMoreModule } from 'ng-readmore';
import { PdfViewerModule } from 'ng2-pdf-viewer';



import {NgxChartsModule} from '@swimlane/ngx-charts';
// import {CountdownModule} from "ngx-countdown";

@NgModule({
  declarations: [
   
  ],
  imports: [
    HumainCapitalManagementRoutingModule,
    NgxOrgChartModule,
    NbIconModule,
    NbTreeGridModule, 
    NbThemeModule,
    NbOptionModule,
    CommonModule,
    NbActionsModule,
    NbSidebarModule,
    NbToastrModule,
    NbMenuModule,
    CKEditorModule,
    FormsModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbDialogModule,
    NbTableModule,
    NbChatModule,
    NbCardModule,
    MatMenuModule,
    NbEvaIconsModule,
    Ng2SearchPipeModule,
    NbAlertModule,
    NbStepperModule,
    ReactiveFormsModule,
    NbListModule,
    NbUserModule,
    NgxPaginationModule,
    NbBadgeModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbAccordionModule,
    NbTagModule,
    NbCheckboxModule,
    NbLayoutModule,
    NbTooltipModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NgxPrintModule,

    PdfViewerModule,
    NbWindowModule,
    NbFormFieldModule,
    CalendarModule.forRoot({
        provide : DateAdapter,
        useFactory : adapterFactory,
    }),
    NbToggleModule,
    NgxSliderModule,
    ReadMoreModule,
    NbProgressBarModule,
    NgxCopyPasteModule,

  //  CountdownModule
    NgxChartsModule,

  ],
  schemas: [NO_ERRORS_SCHEMA], // Add this line
  bootstrap: [HumainCapitalManagementModule],
})
export class HumainCapitalManagementModule { }
