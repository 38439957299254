import { Injectable,NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private inactivityTimer: any;
  private logoutTime = 60 * 60* 1000; // 1 hour in milliseconds
  private warningTime = 55 * 60 * 1000; // 55 minutes in milliseconds

  constructor(private router: Router, private ngZone: NgZone,private dialog: MatDialog) {
    this.startInactivityTimer();
    this.setupActivityListeners();
  }

  public startInactivityTimer() {
    this.inactivityTimer = setTimeout(() => {
      this.ngZone.run(() => { 
        if (!this.isOnLoginPage()){
        alert('You have been inactive for 1 hour. You will be logged out soon.');
        this.router.navigate(['/auth/login']); // Redirect to login page
        }
      });
    }, this.logoutTime);

    // Show warning before logout
    setTimeout(() => {
      this.ngZone.run(() => {
        if (!this.isOnLoginPage()){
        alert('You have been inactive for 55 minutes. You will be logged out soon.');
        this.router.navigate(['/auth/login']);
        }
      });
    
    }, this.warningTime);
  }

  public resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  }

  public setupActivityListeners() {
    window.addEventListener('mousemove', () => this.resetInactivityTimer());
    window.addEventListener('keydown', () => this.resetInactivityTimer());
    window.addEventListener('click', () => this.resetInactivityTimer());
  }
  private isOnLoginPage(): boolean {
    return this.router.url.includes('/auth/login');
  }
}
