import { Component, OnInit } from '@angular/core';
import { MetricsService } from '../../../services/metrics.service';
import { MetricData } from 'app/modules/Monitoring-management/models/MetricData';
import { ReportService } from 'app/modules/Monitoring-management/services/report.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-system-state-list',
  templateUrl: './system-state-list.component.html',
  styleUrls: ['./system-state-list.component.scss']
})
export class SystemStateListComponent implements OnInit {

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#A10A28', '#AAAAAA'] 
  }; 
  currentMetrics: MetricData;
  historicalMetrics: any[];

  chartData: any[];
  chartLabels: string[];
  isLoading = false;
  data : MetricData[]=[]


  constructor(private metricsService: MetricsService, private reportService : ReportService,private exportService:ExportService) { }

  ngOnInit() {
    this.loadCurrentMetrics();
    this.loadHistoricalMetrics();
  }

  loadCurrentMetrics() {
    this.metricsService.getCurrentMetrics().subscribe(data => {
      this.currentMetrics = data;
    });
  }

  loadHistoricalMetrics() {
    this.metricsService.getMetricsHistory().subscribe(data => {
      // Assuming `data` is an array of metric objects with a timestamp field
      const groupedByDay = this.groupDataByDay(data);
      this.data=data;
      this.chartData = [
        {
          "name": "CPU Usage",
          "series": groupedByDay.map(day => ({
            "name": day.date,  // Display the date in the x-axis
            "value": day.cpuUsage // Use the daily average or total CPU usage
          }))
        },
        {
          "name": "Memory Usage",
          "series": groupedByDay.map(day => ({
            "name": day.date,  // Display the date in the x-axis
            "value": day.memoryUsage // Use the daily average or total memory usage
          }))
        },
        {
          "name": "Total Memory Usage",
          "series": groupedByDay.map(day => ({
            "name": day.date,  // Display the date in the x-axis
            "value": day.totalMemory // Include total memory usage
          }))
        }
      ];
    });
  }
  
  groupDataByDay(data: MetricData[]): any[] {
    const result = [];
    const map = new Map();
  
    data.forEach(item => {
      const date = new Date(item.timestamp).toISOString().split('T')[0]; // Extract date part
  
      if (!map.has(date)) {
        map.set(date, { date: date, cpuUsage: 0, memoryUsage: 0, totalMemory: 0, count: 0 });
      }
  
      const entry = map.get(date);
      entry.cpuUsage += item.cpuUsage;
      entry.memoryUsage += item.memoryUsage;
      entry.totalMemory += item.totalMemory;
      entry.count += 1;
    });
  
    map.forEach((value, key) => {
      value.cpuUsage /= value.count; // Average CPU usage
      value.memoryUsage /= value.count; // Average memory usage
      result.push(value);
    });
  
    return result;
  }
  

  generateReport() {
    this.isLoading = true;
    this.reportService.generateReport().subscribe(
      response => {
        this.isLoading = false;
        console.log('Report generated:', response);
        // Optionally show a success message to the user
      },
      error => {
        this.isLoading = false;
        console.error('Error generating report:', error);
        // Optionally show an error message to the user
      }
    );
  }

  exportAsXLSX(): void {
    if (!this.data || !Array.isArray(this.data) || this.data.length === 0) {
      console.error("Data is not available or is not an array");
      return;
    }
  
    const dataForExport = this.data.map((metrics) => ({
      // Convert timestamp to a readable date string
      Timestamp: new Date(metrics.timestamp).toLocaleString(),  
  
      // Round cpuUsage to two decimal places and format as percentage
      CpuUsage: `${metrics.cpuUsage.toFixed(2)}%`,  
  
      // Convert memoryUsage from bytes to MB and round to two decimal places
      MemoryUsage: `${(metrics.memoryUsage / 1048576).toFixed(2)} MB`,  
       TotalMemoryUsage: `${(metrics.totalMemory / 1048576).toFixed(2)} MB`,
      
    }));
  
    this.exportService.exportAsExcelFile(dataForExport, 'SystemReport');
  }
  
  
}
