import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Loginrequest} from '../model/Loginrequest';
import {Auth} from '../model/Auth';
import {Users} from '../model/Users';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


import {ForgetRequest} from '../model/ForgetRequest';
import {ExternApi} from '../../administration-management/service/extern-apis/extern-apis.service';
import { TokenStorageService } from './token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUrl = environment.auth;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    }),
    withCredentials:true,
  };

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }
  private apiUrl = 'https://gateway.manajero.com/administration/ExternalApis';

  logout(email): Observable<any> {
    return this.http.put<any>(`${this.authUrl}logout/${email}`, this.httpOptions);
  }

  register(auth: Auth): Observable<Auth> {
    return this.http.post<Auth>(`${this.authUrl}signup`, auth, this.httpOptions);
  }

/* login(loginRequest: Loginrequest): Observable<Auth> {
    return this.http.post<Auth>(`${this.authUrl}signin`, loginRequest, this.httpOptions);
  }
*/

  login(loginRequest: Loginrequest): Observable<Auth> {
    return this.http.post<Auth>(`${this.authUrl}signin`, loginRequest)
      .pipe(
        tap(response => {
          const userId = response.id; // Récupérez l'ID de l'utilisateur depuis la réponse
          const userrole = response.roles.map((item) => item?.name).join(', ');
          //console.log( 'this userId' + userId),
          //  console.log( 'this userId' + JSON.stringify(userrole)),
          //  console.log('log teb3a amine ' + JSON.stringify(response)),
            this.tokenStorageService.userId(userId)
            this.tokenStorageService.userrole(userrole)
            //localStorage.setItem('userId', userId); // Stockez l'ID de l'utilisateur dans le localStorage
          // @ts-ignore
          // localStorage.setItem('userrole', userrole);
        }),
      );      
  }
/*  getCurrentUser(): string | null {
    return localStorage.getItem('userId');
  }*/

  // login(loginRequest: Loginrequest): Observable<Auth> {
  //   return this.http.post<Auth>(`${this.authUrl}signin`, loginRequest, this.httpOptions)
  //     .pipe(
  //       tap(response => {
  //         const userId = response.id;
  //         const userrole = response.roles.map((item) => item?.name);
  //         // Extract JWT token from the response
  //         const token = response.token;
  //         localStorage.setItem('userId', userId);
  //         //localStorage.setItem('userrole', this.userrole);
  //         // Set the JWT token as a cookie
  //         this.setJwtTokenCookie(token);
  //       })
  //     );  
  // }
  // private setJwtTokenCookie(token: string) {
  //   // Set the JWT token as a cookie with the name "auth_token"
  //   document.cookie = `auth_token=${token};path=/;Secure;SameSite=Strict`;
  // }
  getCurrentUser(): Observable<Users> {
    const userId = localStorage.getItem('userId');
    if (userId) {
      return of({ id: userId } as Users);
    } else {
      return of(null);
    }
  }
 
  // getCurrentUserAudit(): { id: string, email: string } {
  //   const userId = localStorage.getItem('userId');
  //   const email = localStorage.getItem('email');
  //   if (userId && email) {
  //     return { id: userId, email: email };
  //   } else {
  //     return null;
  //   }
  // }

  forgetPWD(forgetRequest: ForgetRequest) {
    return this.http.post<ForgetRequest>(`${this.authUrl}forgotPassword`, forgetRequest);
  }

  resetPWD(forgetRequest: ForgetRequest, token: string) {
    return this.http.put(`${this.authUrl}resetPassword?token=${token}`, forgetRequest, { responseType: 'text' });
  }


  getAllUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}users`, this.httpOptions);
  }
  getUsersAdmins(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}findUsersAdmins`, this.httpOptions);
  }
  getUsersByMatriculate(matriculate: string): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}findUsersByMatriculate/${matriculate}`, this.httpOptions);
  }
  blockUser(userId: string, status: boolean) {
    return this.http.put<Boolean>(`${this.authUrl}blockUser/${userId}/${status}`, {}, this.httpOptions);
  }
  archiveUser(userId: string): Observable<any> {
    return this.http.put(`${this.authUrl}users/${userId}/archive`, {}, { responseType: 'text' });
  }

  unarchiveUser(userId: string): Observable<any> {
    return this.http.put(`${this.authUrl}users/${userId}/unarchive`, {});
  }
  getLockedUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}locked-users`, this.httpOptions);
  }
  getArchivedUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}archived-users`, this.httpOptions);
  }

  disableUser(userId: string, status: boolean) {
    return this.http.put<Boolean>(`${this.authUrl}disableUser/${userId}/${status}`, {}, this.httpOptions);
  }
  getnbUsers(): Observable<Auth[]> {

    return this.http.get<Auth[]>(`${this.authUrl}count-users`, this.httpOptions);

  }

  getUserById(id: String): Observable<Auth> {
    return this.http.get<Auth>(`${this.authUrl}User-by-id/${id}`, this.httpOptions);
  }

  getUser(id: String): Observable<Users> {
    return this.http.get<Users>(`${this.authUrl}findById/${id}`, this.httpOptions);
  }

  getUserByEmail(email: String): Observable<Users> {
    return this.http.get<Users>(`${this.authUrl}findByEmail/${email}`, this.httpOptions);
  }

  updateUser(user: Users): Observable<Users> {
    return this.http.put<Users>(`${this.authUrl}updateUser`, user, this.httpOptions);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(`${this.authUrl}deleteUser/${id}`, this.httpOptions);
  }

  checkPassword(encodedPassword: string, password: string): Observable<any> {
    return this.http.get<any>(
      `${this.authUrl}checkPassword?encPassword=${encodedPassword}&password=${password}`,
      this.httpOptions);
  }

  sendmail(email: string) {
    return this.http.post(`${this.authUrl}updateEmail/${email}`, {}, { responseType: 'text'});
  }

  validateToken(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.authUrl}validateToken/${token}`, this.httpOptions);
  }

  /*
    findAllBYScreening(): Observable<Auth[]> {
      return this.http.get<Auth[]>(`${this.authUrl}application/screening-applications`);
    }


    validate(application:Auth, id : string): Observable<Auth> {
      const path = `${this.authUrl}application/validate/`+id ;
      //@ts-ignore
      return this.http.put<Application>(path ,application , this.httpOptions);
    }

    reject(application:Auth, id : string): Observable<Auth> {
      const path = `${this.authUrl}application/reject/`+id ;
      //@ts-ignore
      return this.http.put<Application>(path ,application , this.httpOptions);
    }
  */


  findAll(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}application/list-applications`);
  }

  findAllBYScreening(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}application/screening-applications`);
  }

  getApplication(id: string): Observable<Auth> {
    return this.http.get<Auth>(`${this.authUrl}application/app/${id}`, this.httpOptions);
  }

  screen(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/screen/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  validate(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/validate/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  reject(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/reject/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  verifyCode(codeVerification: string): Observable<any> {
    return this.http.post(environment.auth + 'verifyCode', { codeVerification }, this.httpOptions);
  }
  regenerateVerificationCode(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    return this.http.post('https://gateway.manajero.com/api/auth/regenerateCode', null, { params , responseType: 'text' });
  }

  sendSMStwilio(phoneNumber: string): Observable<any> {
    const body = { phoneNumber };
    return this.http.post('https://gateway.manajero.com/api/auth/router/sendOTP', body);
  }

  regenerateCodeWithSMS(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    return this.http.post('https://gateway.manajero.com/api/auth/regenerateCode/sms', null, { params, responseType: 'text' });
  }
  getApiStatusByName(apiName: string): Observable<ExternApi> {
    return this.http.get<ExternApi>(`${this.apiUrl}/findByName/${apiName}`);
  }
}