import { Component } from '@angular/core';

@Component({
  selector: 'ngx-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.scss']
})
export class AuditDetailsComponent {

}
