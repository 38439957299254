import { Component, Input, OnChanges } from '@angular/core';
import { logs } from 'app/modules/Monitoring-management/models/logs';
import { ArchiveService } from 'app/modules/Monitoring-management/services/archive.service';
import { ExportService } from 'app/shared/exports/export.service';
import { LocalDataSource } from 'ng2-smart-table';
import { tap } from 'rxjs/operators';
import { ActivityLogsListComponent } from '../../activity-logs-list/activity-logs-list.component';

@Component({
  selector: 'ngx-arch-logs',
  templateUrl: './arch-logs.component.html',
  styleUrls: ['./arch-logs.component.scss']
})
export class ArchLogsComponent {
  source: LocalDataSource = new LocalDataSource();
  archivedLogs: logs[] = [];
  value: String [] = [];
  flippedState: boolean = false;
  selectedLog = null;
 
  constructor( private archiveService : ArchiveService, private exportService: ExportService) {
    this.archiveService.getArchivedLogs().pipe(
      tap((res) => {
        this.archivedLogs = res;
        this.source.load(this.archivedLogs)
       // console.log(this.archivedLogs);
      })
    ).subscribe();
   }

  ngOnInit(): void {
    
  }

  onLogRowSelect(event): void {
    this.selectedLog = event.data;
    this.flip();
    //console.log('iciiiiiEya',this.selectedLog);

  }

  flipBack() {
    this.flippedState = false;
    this.selectedLog = null;
  }


  flip() {
    this.flippedState = !this.flippedState;
    if (this.flippedState) {
      this.source.load(this.archivedLogs); // Load data into the table
    }
  }

  fetchArchivedLogs(): void {
    // this.archiveService.getArchivedLogs().subscribe(
    //   (res) => {
    //     this.archivedLogs = res;
    //     console.log(this.archivedLogs);
    //   }
    // );
  }
  

  settings = {
    mode: 'external',
    actions: {
      add: false, 
      edit: false, 
      delete: false,
    },
    columns: {
      logger: {
        title: 'Logger',
        type: 'string',
        valuePrepareFunction:(logger)=>{
          const resSplit = logger.split('.')
          const resFinal = resSplit.pop()
        return resFinal
        }
        
      },
      level: {
        title: 'Level',
        type: 'html',
        valuePrepareFunction: (level) => {

          switch (level) {
            case 'INFO': {
              return `<h5><span class="badge badge-primary">${level}  </span></h5>`;
            }
            case 'ERROR': {
              return `<h5><span class="badge badge-danger">${level} </span></h5>`;
            }
            case 'WARN': {
              return `<h5><span class="badge badge-warning">${level} </span></h5>`;
            }
            default: {
              return `<h5><span class="badge badge-basic"> </span></h5>`;
            }
          }
        }
       
      },
      timestamp: {
        title: 'Timestamp',
        type: 'string',
        sortDirection: 'desc',
        valuePrepareFunction:(timestamp)=>{
          const date = new Date(timestamp)
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        return formattedDate
        }
       
      },
      thread: {
        title: 'Thread',
        type: 'string',
       
      },
      browser: {
        title: 'Browser',
        type: 'html',
        valuePrepareFunction: (browser) => {
          if (browser && typeof browser === 'string') { 
            if (browser && typeof browser === 'string') { 
              const resSplit = browser.split(' ')[0];

              if (resSplit.toLowerCase() === "chrome") {
                return `<span><i class="fab fa-chrome fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
              } else if (resSplit.toLowerCase() === "mozilla") {
                return `<span><i class="fab fa-firefox fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
              } else if(resSplit.toLowerCase() === "opera"){
                return `<span><i class="fab fa-opera fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
              }else if(resSplit.toLowerCase() === "instagram"){
                return `<span><i class="fab fa-instagram fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
              }else if(resSplit.toLowerCase() === "facebook"){
                return `<span><i class="fab fa-facebook fa-ms style=" padding-right: 20%;"></i></span> `+` ${resSplit}`;
              }else {
              return `<span><i class="fab fa-edge" style="padding-right: 20%;"></i></span> `+` ${resSplit}`;
              
            }
          }
        }
        }     
        
      
        
       
      },
      device: {
        title: 'Device',
        type: 'string',
      }
     
    },
  };

  selectedMethod() {
    console.log('Selected Values:', this.value);

    if (!Array.isArray(this.value)) {
      this.value = [this.value];
    }

    if (this.value.length > 0 && !this.value.includes('all levels')) {
      const searchString = this.value.join(',');
      console.log('Search String:', searchString);
      this.source.setFilter([
        {
          field: 'level',
          search: searchString,
        }
      ], true);
      console.log('Filtered Data:', this.source.getFilter());
    } else {
      this.source.setFilter([], true);
      console.log('All Data:', this.source.getAll());
    }
  }

  exportAsXLSX(): void {
    // Create a new array to hold the data for export
    const dataForExport = this.archivedLogs.map(logs => ({
      Logger: logs.logger,
      Thread: logs.thread,
      Device: logs.device,
      Browser: logs.browser,
      Level: logs.level,
      Time: logs.timestamp,
      Username : logs.userName,
      Message : logs.message,
      SessionID : logs.sessionId,
      IPAddrees : logs.ipAddress,
      OperatingSystem : logs.operatingSystem,
      Role : logs.role
      
    }));
    //console.log(dataForExport,"exceel")
    // Use the new array for the export
    this.exportService.exportAsExcelFile(dataForExport, 'dataArchivedUsers');
  }

 
}
