import { Component } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
interface AuditLog {
  date: Date;
  user: string;
  action: string;
}

@Component({
  selector: 'ngx-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent {
//   auditLogs: AuditLog[] = [
//     { date: new Date(), user: 'Admin', action: 'Modified configuration settings' },
//     { date: new Date(), user: 'User123', action: 'Viewed system status' },
//     // Ajoutez plus de journaux d'audit au besoin
//   ]
//   logActions = {
//     create: false,
//     read: false,
//     update: false,
//     delete: false
//   };
//   detailLevel: string = 'low';
//   retentionPolicy: number = 30;
//   isDarkMode: boolean = false;

//   constructor(private themeService: NbThemeService) {
//     this.themeService.onThemeChange()
//       .subscribe(theme => {
//         this.isDarkMode = theme === 'dark';
//       });
//   }

//   saveConfig(): void {
//     // Here you would implement the logic to send the configuration settings to the backend
//     const configSettings = {
//       logActions: this.logActions,
//       detailLevel: this.detailLevel,
//       retentionPolicy: this.retentionPolicy
//     };
//     console.log('Saving configuration settings:', configSettings);
//     // Send configSettings to the backend
//   }

// }
isDarkMode = false;
  logActions = {
    create: false,
    read: false,
    update: false,
    delete: false
  };
  detailLevel = 'medium';
  severityLevel = 'avertissement';
  auditFrequency = 'quotidien';
  auditScope = 'systeme';
  retentionPolicy = '30';
  auditTriggers = 'Configuration Change';
  auditNotifications = {
    email: true,
    sms: true,
    slack: false
  };
  managementToolsIntegration = {
    kibana: true,
    splunk: true,
    datadog: false
  };
  auditReportsCustomization = 'both';
  complianceControls = 'PCI-DSS';
  modificationHistory = '6months';


  saveConfig() {
    const config = {
      logActions: this.logActions,
      detailLevel: this.detailLevel,
      severityLevel: this.severityLevel,
      auditFrequency: this.auditFrequency,
      auditScope: this.auditScope,
      retentionPolicy: this.retentionPolicy,
      auditTriggers: this.auditTriggers,
      auditNotifications: this.auditNotifications,
      managementToolsIntegration: this.managementToolsIntegration,
      auditReportsCustomization: this.auditReportsCustomization,
      complianceControls: this.complianceControls,
      modificationHistory: this.modificationHistory
    };
    console.log('Configuration Saved:', config);
    // Here you can add the logic to save the configuration, for example, making an API call
  }
}
