import { Component } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Ng2SmartTableComponent } from 'ng2-smart-table';


@Component({
  selector: 'ngx-access-control-list',
  templateUrl: './access-control-list.component.html',
  styleUrls: ['./access-control-list.component.scss']
})
export class AccessControlListComponent {
  source=new LocalDataSource();
  constructor() {}
  ngOnInit(): void {
    this.loadStaticData();
  }

  settings = {
    columns: {
      id: {
        title: 'ID',
        editable: false,
      },
      role: {
        title: 'Role',
        editable: true,
      },
      permissions: {
        title: 'Permissions',
        editable: true,
      },
      modules: {
        title: 'Modules',
        editable: true,
      },
    },
    actions: {
      columnTitle: 'Actions',
      add: false,
      edit: true,
      delete: false,
      position: 'right',
    },
    edit: {
      confirmSave: true,
    },
  };

  // Sample data for ngx-smart-table
  private loadStaticData(): void {
    const staticData = [
      { id: 1, role: 'Admin', permissions: 'Full access' ,modules:'RH'},
      { id: 2, role: 'employee', permissions: 'Limited access',modules:'Stock' },
      // Add more static data as needed
    ];

    this.source.load(staticData);
  }

  openUserModal(action: 'create' | 'edit'): void {
    // Implement modal opening logic here
  }
}
