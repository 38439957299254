import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { logs } from '../models/logs';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
   
 
  constructor(private http: HttpClient) { } 

  getArchivedLogs(): Observable<logs[]> {
    return this.http.get<logs[]>('https://gateway.manajero.com/api/auth/archived');
  }
}