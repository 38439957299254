<div class="container" [class.dark-mode]="isDarkMode">
  <div class="row">
    <div class="col-md-8 mx-auto">
      <nb-card [class.dark-mode]="isDarkMode">
        <nb-card-body>
          <h2 class="card-title text-center mb-4">Auditing Configuration Settings</h2>
          
          <!-- Actions to Log -->
          <div class="form-group">
            <label class="font-weight-bold">Actions to Log :</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="logActions.create" id="createCheckbox">
              <label class="form-check-label" for="createCheckbox">Create</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="logActions.read" id="readCheckbox">
              <label class="form-check-label" for="readCheckbox">Read</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="logActions.update" id="updateCheckbox">
              <label class="form-check-label" for="updateCheckbox">Update</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="logActions.delete" id="deleteCheckbox">
              <label class="form-check-label" for="deleteCheckbox">Delete</label>
            </div>
          </div>
          
          <!-- Level of Detail -->
          <div class="form-group">
            <label class="font-weight-bold">Level of Detail :</label>
            <select class="form-control" [(ngModel)]="detailLevel">
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>

          <!-- Niveaux de Sévérité des Audits -->
          <div class="form-group">
            <label class="font-weight-bold">Niveaux de Sévérité des Audits :</label>
            <select class="form-control" [(ngModel)]="severityLevel">
              <option value="critique">Critique</option>
              <option value="avertissement">Avertissement</option>
              <option value="informationnel">Informationnel</option>
            </select>
          </div>

          <!-- Fréquence des Audits -->
          <div class="form-group">
            <label class="font-weight-bold">Fréquence des Audits :</label>
            <select class="form-control" [(ngModel)]="auditFrequency">
              <option value="quotidien">Quotidiennement</option>
              <option value="hebdomadaire">Hebdomadairement</option>
              <option value="mensuel">Mensuellement</option>
            </select>
          </div>

          <!-- Étendue des Audits -->
          <div class="form-group">
            <label class="font-weight-bold">Étendue des Audits :</label>
            <select class="form-control" [(ngModel)]="auditScope">
              <option value="systeme">Ensemble du Système</option>
              <option value="sous-systeme">Sous-système spécifique</option>
              <option value="parametres">Paramètres individuels</option>
            </select>
          </div>

          <!-- Politiques de Rétention -->
          <div class="form-group">
            <label class="font-weight-bold">Politiques de Rétention :</label>
            <select class="form-control" [(ngModel)]="retentionPolicy">
              <option value="30">30 jours</option>
              <option value="60">60 jours</option>
              <option value="90">90 jours</option>
            </select>
          </div>

        <!-- Déclencheurs d'Audit -->
        <div class="form-group">
          <label class="font-weight-bold">Déclencheurs d'Audit :</label>
          <select class="form-control" [(ngModel)]="auditTriggers">
            <option value="Configuration Change">Changement de Configuration</option>
            <option value="Unauthorized Access">Accès Non Autorisé</option>
            <option value="Login Attempt">Tentative de Connexion</option>
          </select>
        </div>

        <!-- Notifications d'Audit -->
        <div class="form-group">
          <label class="font-weight-bold">Notifications d'Audit :</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="auditNotifications.email" id="emailCheckbox">
            <label class="form-check-label" for="emailCheckbox">Email</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="auditNotifications.sms" id="smsCheckbox">
            <label class="form-check-label" for="smsCheckbox">SMS</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="auditNotifications.slack" id="slackCheckbox">
            <label class="form-check-label" for="slackCheckbox">Slack</label>
          </div>
        </div>

        <!-- Intégration avec des Outils de Gestion -->
        <div class="form-group">
          <label class="font-weight-bold">Intégration avec des Outils de Gestion :</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="managementToolsIntegration.kibana" id="kibanaCheckbox">
            <label class="form-check-label" for="kibanaCheckbox">Kibana</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="managementToolsIntegration.splunk" id="splunkCheckbox">
            <label class="form-check-label" for="splunkCheckbox">Splunk</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="managementToolsIntegration.datadog" id="datadogCheckbox">
            <label class="form-check-label" for="datadogCheckbox">Datadog</label>
          </div>
        </div>

        <!-- Personnalisation des Rapports d'Audit -->
        <div class="form-group">
          <label class="font-weight-bold">Personnalisation des Rapports d'Audit :</label>
          <select class="form-control" [(ngModel)]="auditReportsCustomization">
            <option value="summary">Summary Only</option>
            <option value="details">Details Only</option>
            <option value="both">Summary and Details</option>
          </select>
        </div>

        <!-- Contrôles de Conformité -->
        <div class="form-group">
          <label class="font-weight-bold">Contrôles de Conformité :</label>
          <select class="form-control" [(ngModel)]="complianceControls">
            <option value="PCI-DSS">PCI-DSS</option>
            <option value="GDPR">GDPR</option>
            <option value="HIPAA">HIPAA</option>
          </select>
        </div>

        <!-- Historique des Modifications -->
        <div class="form-group">
          <label class="font-weight-bold">Historique des Modifications :</label>
          <select class="form-control" [(ngModel)]="modificationHistory">
            <option value="1month">Last 1 month</option>
            <option value="3months">Last 3 months</option>
            <option value="6months">Last 6 months</option>
            <option value="1year">Last 1 year</option>
          </select>
        </div>

          <!-- Save Button -->
          <div class="text-center">
            <button nbButton status="primary" (click)="saveConfig()">Save Configuration</button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
