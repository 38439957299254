import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecurityAlert } from '../models/SecurityAlert';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private apiUrl = 'https://gateway.manajero.com/api/alert/alerts' ;

  constructor(private http: HttpClient) {}
  getAlerts(): Observable<SecurityAlert[]> {
    return this.http.get<SecurityAlert[]>(this.apiUrl);
  }

  
 
}